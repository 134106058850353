@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.main {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    @include font-montserrat-bold;
    font-size: 3rem;
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);
    text-align: center;

    @include tablet {
      font-size: 3.75rem;
    }
  }

  &__img {
    height: 500px;
    padding: 3rem 0;
    object-fit: contain;

    @include tablet {
      height: 55dvh;
    }
  }

  &__button {
    @include custom-button;
    margin: 0 auto;
  }

}

.appear {
  @include fade-in;
}

.disappear {
  @include fade-out;
}