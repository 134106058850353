@use '../../styles/partials/globals' as *;
@use '../../styles/partials/mixins' as *;

.movie-card {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 1.5rem;

  @include desktop {
    width: 14%;
  }

  &__image {
    aspect-ratio: 2 / 3;
    margin-bottom: .5rem;
    object-fit: cover;
    border-radius: 3px;
    box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);

    @include desktop {
      // height: 500px;
    }
  }

  &__title {
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);
    font-size: 1rem;

    @include tablet {
      font-size: 1.25rem;
    }
  }

  &__year {
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.6);
    @include font-montserrat-light;
    font-size: 0.875rem;

    @include tablet {
      font-size: 1rem;
    }
  }

  &__score {
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);
    @include font-montserrat-reg;
    font-size: 0.75rem;

    @include tablet {
      font-size: 0.875rem;
    }
  }
}

.loaded {
  @include fade-in;
}

.loading {
  opacity: 0;
  display: none;
}