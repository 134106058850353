@use '../../styles/partials/globals' as *;
@use '../../styles/partials/mixins' as *;

.mood {
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  display: none;

  @include tablet {
    width: 60%;
  }

  &__header {
    @include font-montserrat-bold;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);
    text-align: center;

    @include tablet {
      font-size: 2.75rem;
    }
  }

  &__option {
    cursor: pointer;
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);
    font-size: 3rem;

    &:active {
      transform: translate(2px, 3px);
    }

    @include tablet {
      font-size: 4rem;
    }
  }
}