@use '../../styles/partials/globals' as *;
@use '../../styles/partials/mixins' as *;

.rating {
  position: absolute;
  left: 50%;
  top: 50%;
  // transform: translate(50%, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include fade-in-abs;


  &__header {
    @include font-montserrat-bold;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-shadow: 3px 4px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
    white-space: nowrap;
    user-select: none;

    @include tablet {
      font-size: 2.5rem;
    }
  }

  &__img {}

  &__buttons {
    display: flex;
    gap: 1.5rem;
  }

  &__button {
    @include custom-button();
    width: 6rem;
    user-select: none;
  }
}