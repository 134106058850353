*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

ul {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root,
#__next {
  isolation: isolate;
}

body {
  background-color: #ff6663;
  -webkit-font-smoothing: antialiased;
  color: white;
  height: 100%;
  font-family: 'Montserrat', Arial, sans-serif;
}

.hide {
  display: none;
}

html {
  height: 100%;
}