@import './variables';

// Breakpoints

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}


//Montserrat
@mixin font-montserrat-reg {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: normal;
  // font-display: swap;
}

@mixin font-montserrat-bold {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: bold;
  // font-display: swap;
}

@mixin font-montserrat-light {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: lighter;
  // font-display: swap;
}


// Fira Sans
@mixin font-firasans-reg {
  font-family: 'Fira Sans', Arial, sans-serif;
  font-weight: normal;
  // font-display: swap;
}

@mixin font-firasans-bold {
  font-family: 'Fira Sans', Arial, sans-serif;
  font-weight: bold;
  // font-display: swap;
}

@mixin font-firasans-light {
  font-family: 'Fira Sans', Arial, sans-serif;
  font-weight: lighter;
  // font-display: swap;
}

@mixin custom-button() {
  @include font-montserrat-bold;
  color: #ff6663;
  font-size: 1.2rem;
  // font-weight: 600;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border-color: #ff6663;
  cursor: pointer;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.2);

  @include tablet {
    font-size: 1.5rem;
  }

  &:hover {
    // background-color: $silver;
    // color: white;
    // border-color: #d45452;
    transform: translate(-1px, -1px);
    box-shadow: 4px 5px 6px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: #ff6663;
    color: white;
    border-color: #ff6663;
    transform: translate(2px, 2px);
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
  }
}


// ANIMATIONS

// Normal Animations
@mixin fade-in {
  animation: fadeIn 0.75s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin fade-out {
  animation: fadeOut 0.75s ease-in-out forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    // 99% {
    //   opacity: 0;
    // }

    100% {
      transform: translateY(-30px);
      opacity: 0;
      display: none;
    }
  }
}

// Position Absolute Animations
@mixin fade-in-abs {
  animation: fadeInAbs 0.75s ease-in-out forwards;
}

@mixin fade-out-abs {
  animation: fadeOutAbs 0.75s ease-in-out forwards;
}

@keyframes fadeInAbs {
  0% {
    opacity: 0;
    transform: translate(-50%, 0%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeOutAbs {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -100%);
    display: none;
  }
}